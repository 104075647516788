export const monthNames = {
  '01': 'Janeiro',
  '02': 'Fevereiro',
  '03': 'Março',
  '04': 'Abril',
  '05': 'Maio',
  '06': 'Junho',
  '07': 'Julho',
  '08': 'Agosto',
  '09': 'Setembro',
  '10': 'Outubro',
  '11': 'Novembro',
  '12': 'Dezembro'
}

export const monthTotal = {
  '01': 31,
  '02': 29,
  '03': 31,
  '04': 30,
  '05': 31,
  '06': 30,
  '07': 31,
  '08': 31,
  '09': 30,
  '10': 31,
  '11': 30,
  '12': 31
}

export const daysOfWeek = {
  0 : 'D',
  1 : 'S',
  2 : 'T',
  3 : 'Q',
  4 : 'Q',
  5 : 'S',
  6 : 'S'
}

function addPadding(value) {
  if (value < 10)  {
    return '0' + value;
  }
  return '' + value;
}

export function getTodayStr() {
  const date = new Date();
  return date.getFullYear() + addPadding(date.getMonth()+1) + addPadding(date.getDate());
}

export function getYearMonthName(dateStr) {
  return monthNames[dateStr.substring(4, 6)] + " " + dateStr.substring(0, 4);
}


export function getFullDateName(dateStr) {
  return "" + parseInt(dateStr.substring(6, 8)) + " de " + getYearMonthName(dateStr);
}

export function getFullDateNameFromId(boardId) {
  const dateStr = boardId.substring(5)
  return "" + parseInt(dateStr.substring(6, 8)) + " de " + getYearMonthName(dateStr);
}

export function getMonth(dateStr) {
  return monthNames[dateStr.substring(4, 6)]
}



export function getTodayBoardId(prefix = "") {
    if (prefix == "") {
      prefix = 'daily'    
    }
    return prefix + getTodayStr();
  }

