<template>
  
  <h1>Arquivo</h1>
  <div class="legenda-chips">
    <div id="leg1" class="legenda-novo">INÉDITO</div>
    <div id="leg2" class="legenda-usado">PARCIAL</div>
    <div id="leg3" class="legenda-concluido">COMPLETO</div>
  </div>

  
  <div class="arquivo-container">
    <div class="month" v-for="month in monthsTitle" :key="month">
      <h3 class="month-header">{{ month }}</h3>
      <div class="calendar" > 
        <div v-for="date in orderedDates" :key="date">
          <div :id='"daily"+ date' class="day" v-if="getYearMonthName(date) === month" @click="toggleBoardInfo(date)"> 
            {{ parseInt(date.substring(6,8)) }} 
          </div>
        </div>
      </div>
    </div>
  </div>
  <BoardInfo :isVisible="showBoardInfo" :boardDate="boardInfoId" @close="toggleBoardInfo()"></BoardInfo>
</template>

<script>
import api from '../services/api'; 
import BoardInfo from '../components/BoardInfo.vue';
import { getTodayBoardId, getTodayStr, monthNames } from '../functions';
import { themes } from '../themes';

export default {
  name: 'ArchiveView',
  components: {BoardInfo},
  data() {
    return {
      //map containing { boardId : numericaldate }   like: { 'daily20240603' : '20240603'}
      //to be used to fetch info about the boards from API/Local Storage and for sorting/display purposes
      boardIds: {},
      orderedDates: [],
      monthsTitle: [],
      monthsNum: [],
      showBoardInfo: false,
      showTest: false,
      boardInfoId: ""
    }
  },
  updated() {
    this.paintComponents();
  },
  created() {
    this.checkBoardIds();
  },
  mounted() {
    this.setColor('leg1', 'crimson');
    this.setColor('leg2', 'amber');
    this.setColor('leg3', 'green');
    this.paintComponents();
  },
  methods: {
    checkBoardIds() {
      if (localStorage.getItem('boardIds') === null) {
        this.fetchBoardIdsAPI();
      } else {
        const tempIds = JSON.parse(localStorage.getItem('boardIds'));
        const todayId = getTodayBoardId('daily');
        let isUptoDate = false;
        for (let i = Object.keys(tempIds).length -1 ; i >= 0; i --) {
          if (todayId === Object.keys(tempIds)[i]) {
            isUptoDate = true;
            break;
          }
        }
        if (isUptoDate) {
          this.boardIds = tempIds;
          this.getMonths();
          this.getOrderedDates();
        } else {
          this.fetchBoardIdsAPI();
        }
      } 
    },  
    async fetchBoardIdsAPI() {
      try {
        const response = await api.getBoardIdsFromAPI();
        if (response.data.body) {
          const ids = JSON.parse(response.data.body);
          this.getDates(ids);
        }
      } catch (err) {
        console.log(err);
      }
    },
    getDates(ids) {
      ids.forEach(element => {
        if (element[0].substring(5) <= getTodayStr()) {
          const fullDate = element[0].substring(5)
          this.boardIds[element[0]] = fullDate;           
        }
      });
      const newIds = JSON.stringify(this.boardIds);
      localStorage.setItem('boardIds', newIds);
      this.getOrderedDates();
      this.getMonths();
    },
    getYearMonthName(dateStr) {
      return monthNames[dateStr.substring(4, 6)] + " " + dateStr.substring(0, 4);
    },
    paintComponents() {
      Object.keys(this.boardIds).forEach(id => {
        if(localStorage.getItem(id) === null) {
          this.setColor(id, 'crimson');
        } else {
          const gameData = JSON.parse(localStorage.getItem(id));
          if (gameData.playerData.correctGuesses.length === gameData.boardData.totalWords) {
            this.setColor(id, 'green');
          } else if (gameData.playerData.correctGuesses.length > 0 || gameData.playerData.correctBonusGuesses.length > 0) {
            this.setColor(id, 'amber');
          } else {
            this.setColor(id, 'crimson');
          }
        } 
      });
    },
    setColor(id, color) {
      document.getElementById(id).style.setProperty('background', themes[color]['chosencolor']);
      document.getElementById(id).style.setProperty('border-color', themes[color]['maincolor']);
    },
    toggleBoardInfo(date = "") {
      this.showBoardInfo = !this.showBoardInfo;
      this.boardInfoId = date;
    },
    getMonths(){
      Object.values(this.boardIds).forEach(fullDate => {
        const yearMonth = fullDate.substring(0, 6);
        
        if (this.monthsNum.indexOf(yearMonth) === -1) {
          this.monthsNum.push(yearMonth);
        }
      });
      this.monthsNum.sort().reverse();

      this.monthsNum.forEach(monthNum => {
        
        this.monthsTitle.push(this.getYearMonthName(monthNum))
      })
    },
    getOrderedDates() {
      this.orderedDates = Object.values(this.boardIds).sort();
    }
    
  }
}

</script>

<style>

.legenda-chips {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
}
.legenda-novo, .legenda-usado, .legenda-concluido {
  width: 90px;
  height: 20px;
  border-radius: 15px;
  border: 5px solid;
}
.arquivo-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-height: calc(100vh - 250px);
  overflow-y: auto; 
  width: 90%;
  padding: 10px;
  margin-top: 20px;
}
.month {
  margin-bottom: 5px;
  width: 300px;
  text-align: left;
  border: 5px solid var(--maincolor);
  border-radius: 15px;
  padding: 15px;
  background-color: #333;
}
.month-header {
  margin-top: 5px;
}
.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.day {
  border: solid var(--maincolor);
  background: var(--chosencolor);
  text-align: center;
  border-radius: 15px;
}
</style>
