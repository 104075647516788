<template>
  <div>
    <h1 class="member-header">Preferências</h1>
    <label for="theme-select">Tema:  </label>
    <select id="theme-select" class="theme-select" v-model="currentTheme" @change="changeTheme($event.target.value)">
        <option class="option-theme" v-for="theme in themeNames" :id="'option-'+theme" :key="theme" :value="theme">{{theme}}</option>
    </select>
  </div>
</template>

<script>
import { themes, defaultTheme } from '../themes';

export default {
  name: 'MemberSettings',
  data() {
    return {
      themeNames: Object.keys(themes),
      currentTheme: ''
    }
  },
  beforeMount() {
      if (localStorage.getItem('theme')) {
        this.currentTheme = localStorage.getItem('theme');
      } else {
        this.currentTheme = defaultTheme;
      }
  },
  mounted() {
    this.themeNames.forEach(theme => {
      document.getElementById('option-' + theme).style.setProperty('background', themes[theme]['maincolor']);
      document.getElementById('option-' + theme).style.setProperty(':hover', themes[theme]['maincolor']);
    });
    document.getElementById('theme-select').style.setProperty('background', themes[this.currentTheme]['maincolor']);

  },
  methods: {
    changeTheme(themeName) {
      const theme = themes[themeName];
      Object.keys(theme).forEach(key => {
        document.documentElement.style.setProperty(`--${key}`, theme[key]);

      });
      document.getElementById('theme-select').style.setProperty('background', themes[themeName]['maincolor']);
      localStorage.setItem('theme', themeName);
    }
  }
}
</script>

<style>
label {
  font-size: 1.3em;
  font-weight: bold;
}
.fa.fa-cogs {
  font-size: 56px;
}
.option-theme {
  color: white;
  font-weight: bold;
  font-size: 1.0em;
  pointer-events: none;
}
.theme-select {
  background: var(--maincolor);
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}
</style>