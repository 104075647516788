function darkenColor(hex, percent) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  r = Math.max(0, Math.floor(r * (1 - percent)));
  g = Math.max(0, Math.floor(g * (1 - percent)));
  b = Math.max(0, Math.floor(b * (1 - percent)));

  const toHex = (num) => num.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}



const darkPercent = 0.3;

const crimson = '#DC143C';
const magenta = '#DC14A0';
const violet = '#B414DC';
const purple = '#5014DC';
const blue = '#143CDC';
const sky = '#14A0DC';
const cyan = '#14DCB4';
const green = '#009B3A';
const avocado = '#A0DC14';
const amber = '#FFBF00';
const orange = '#DC5014';


export const themes = {
  crimson: {maincolor: crimson, chosencolor: darkenColor(crimson, darkPercent)},
  magenta: {maincolor: magenta, chosencolor:  darkenColor(magenta, darkPercent)},
  violet: {maincolor: violet, chosencolor:  darkenColor(violet, darkPercent)},
  purple: {maincolor: purple, chosencolor:  darkenColor(purple, darkPercent)},
  blue: {maincolor: blue, chosencolor:  darkenColor(blue, darkPercent)},
  sky: {maincolor: sky, chosencolor:  darkenColor(sky, darkPercent)},
  cyan: {maincolor: cyan, chosencolor:  darkenColor(cyan, darkPercent)},
  green: {maincolor: green, chosencolor:  darkenColor(green, darkPercent)},
  avocado: {maincolor: avocado, chosencolor:  darkenColor(avocado, darkPercent)},
  amber: {maincolor: amber, chosencolor:  darkenColor(amber, darkPercent)},
  orange: {maincolor: orange, chosencolor:  darkenColor(orange, darkPercent)},
}

export const defaultTheme = 'crimson';