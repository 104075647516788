<template>
      
    <div class="placar"> 
      <!--<i class="fa fa-trash" @click="clearData"></i>-->
      <p> Tabuleiro de {{ boardLongId }}</p>
      <div class="placar-container">
        <i class="fa fa-book" @click="toggleResultList" v-if="isSmallScreen"></i>
        <div class="score-title" @click="toggleResultList" >
         <b> Placar: {{ playerData.correctGuesses.length }} / {{ boardData.totalWords }}</b> <i>(+{{ playerData.correctBonusGuesses.length }})</i>
        </div>
        <i class="fa fa-share-alt" @click="toggleShare"></i>
      </div>
      <h3 class="result" id="result"><b> {{ result }}</b></h3>
    </div>
    
    <div class="grade">
      <div v-show="showBoard" class="game">
        <Board :rows='boardData.rows' :columns='boardData.columns' :tiles="boardData.tiles" 
        :tilesStarting="playerData.tilesStarting" :tilesUsed="playerData.tilesUsed" :dica="dica" 
        :showTilesStarting="showTilesStarting" :showTilesUsed="showTilesUsed" 
        @cleanDica="cleanDica" @findResult="getResult"/>
      </div>
      <div v-show="showResultList" class="result-list">
        <p class="word-list-header">Ajuda:</p> 
          <div class="hint-container">
            <span class="checkbox-title" @click="toggleHints"><input class="checkbox" type="checkbox" v-model="showHints" :checked="showHints"> 
              Revelar algumas letras</span>
            <span class="checkbox-title" @click="toggleTilesStarting"><input class="checkbox" type="checkbox" v-model="showTilesStarting" :checked="showTilesStarting">
              Revelar letras iniciais</span>
            <span class="checkbox-title" @click="toggleTilesUsed"><input class="checkbox" type="checkbox" v-model="showTilesUsed" :checked="showTilesUsed">
              Revelar letras usadas</span>
            <span class="checkbox-title"><i class="fa fa-gift" @click="getDica"></i> 
              Revelar uma palavra aleatória</span>     
            <br>
          </div>
        <p class="word-list-header">Palavras Encontradas:</p>
        <div class="word-list"> 
          <div v-for="(count, index) in boardData.wordCount" :key="index">
            <div v-if="boardData.wordCount[index] > 0">
              <p class="words-header">{{ index }} letras <i> (restam {{ playerData.wordsLeft[index] }}) </i></p>
              <ul :class='"word-list-by-length length-" + index'>
                <div v-for="word in orderedGuesses[index]" :key="word.index">
                  <li @click="openDictLink(word)" class="words" >{{ word }}</li>  
                </div>
              </ul>
            </div>
          </div>
          <br>
          <p class="word-list-header">Palavras Bônus ({{ playerData.correctBonusGuesses.length }} / {{ bonusGuessesLength }}):</p>
          <div class="bonus-container">
            <div v-for="bonusWord in orderedBonusGuesses" :key="bonusWord">
              <li class="words" >{{ bonusWord }}</li>
            </div>
            <br><br><br><br><br><br><br><br>
          </div>
        </div>
      </div>

    </div>
    <ModalShare :isVisible="showShare" :totalNormalWords="playerData.correctGuesses.length" 
    :totalBonusWords="playerData.correctBonusGuesses.length"  :totalGameTime="totalGameTime" @close="toggleShare"></ModalShare>
</template>

<script>
import Board from '../components/Board.vue'
import ModalShare from '../components/ModalShare.vue'
import api from '../services/api';
import { getTodayBoardId , getFullDateNameFromId } from '../functions.js'

export default {
  name: 'HomeView',
  components: { Board , ModalShare },
   
  data() {
    return {
      
      boardId: '',
      boardLongId: '',
      wordMinLength: 4,

      //this is a temporary solution to choose a board for the game
      //TODO - make a daily selector
      //boardId: "daily20240519",

      //this stores the immutable information from DB
      boardData: {
        language: "",
        rows: 0,
        columns: 0,
        totalWords: 0,
        tiles: [],
        wordCount: [],
        normalGuesses: {},
        tilesStarting: [],
        tilesUsed: [],
        bonusGuesses: {}
      },
      //and this is the player data that is updated every move and saved to the LocalStorage
      playerData: {
        startTime: 0,
        endTime: 0,
        correctGuesses: [],
        correctBonusGuesses: [],
        wordsLeft: [],
        tilesStarting: [],
        tilesUsed: [],
      },
    
      //local game variables that are only relevant to the session

      timeOut: null,
      showShare: false,
      showBoard: true,
      showResultList: true,
      isSmallScreen: false,
      showHints: false,
      showTilesStarting: false,
      showTilesUsed: false,

      result: "Boa Sorte!",
      dica: ""
    }
  },
  computed: {
    totalGameTime() {
      if (this.playerData.startTime === 0 && this.playerData.endTime === 0) {
        return 0;
      }
      else if (this.playerData.endTime === 0) {
        this.playerData.endTime = this.getCurrentTime();
      }
      return Math.round((this.playerData.endTime - this.playerData.startTime) / 60000);
    },  
    orderedGuesses() {
      const grupo = [];
      Object.keys(this.boardData.normalGuesses).forEach((word) => {
        if (this.showHints || this.playerData.correctGuesses.includes(word)) {
          const length = word.length;
          if (!grupo[length]) {
            grupo[length] = [];
          }
          if (this.playerData.correctGuesses.includes(word)) {
            grupo[length].push(word);
          } else {
            if (this.showHints) {
              grupo[length].push(this.getHintMask(word));
            } 
          }
        }
      })  
      return grupo;
    },
    orderedBonusGuesses() {
      return this.playerData.correctBonusGuesses.sort();
    },
    remainingGuesses() {
      let guesses = []
      for (let i = 0; i < Object.keys(this.boardData.normalGuesses).length; i++) {
        const word = Object.keys(this.boardData.normalGuesses)[i];
        if (!this.playerData.correctGuesses.includes(word)) { 
          guesses.push(word);
        }   
      }
      return guesses;
    },
    bonusGuessesLength() {
      return Object.keys(this.boardData.bonusGuesses).length;
    }
  },
  created() {
    this.checkScreenSize();
    this.getBoardId();
    this.getData(this.boardId);
    
  },
  mounted() {
    window.addEventListener('resize', this.checkScreenSize);
    this.getSessionStorage();
  },
  unmounted() {
    clearInterval(this.timeOut);
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: { 
    clearData() {
      localStorage.clear();
      location.reload();
    },
    getBoardId() {
      if ((sessionStorage.getItem('boardIdToPlay') !== null) && sessionStorage.getItem('boardIdToPlay') !== getTodayBoardId()) {
        this.boardId = sessionStorage.getItem('boardIdToPlay');
        this.boardLongId = getFullDateNameFromId(this.boardId);
      } else {
        this.boardId = getTodayBoardId();
        this.boardLongId = "Hoje";
      }
    },
    checkScreenSize() {
      if (window.innerWidth <= 600 && this.isSmallScreen === false) {
        this.isSmallScreen = true;
        this.showResultList = false;
        this.showBoard = true;
        
      } else if (window.innerWidth > 600 && this.isSmallScreen === true) {
        this.isSmallScreen = false;
        this.showResultList = true;
        this.showBoard = true;
      }
    },

    getData(boardId) {
      if (localStorage.getItem(boardId) != null) {
        const totalDailyData = JSON.parse(localStorage.getItem(boardId));
        this.boardData = totalDailyData.boardData;
        this.playerData = totalDailyData.playerData;
        console.log("got data from localStorage");
      } else {
        this.fetchBoard(boardId);
      }
    },
    async fetchBoard(boardId) {
      try {
        const response = await api.getBoardDataFromAPI(boardId);
        const boardJson = response.data;
        this.boardData = JSON.parse(boardJson.Data);
        this.playerData.tilesStarting = this.boardData.tilesStarting.slice();
        this.playerData.tilesUsed = this.boardData.tilesUsed.slice();
        this.playerData.wordsLeft = this.boardData.wordCount.slice();
        console.log("got data from API");
        this.saveToLocalStorage(); 
      } catch (err) {
        console.error(err);
      }
      return
    },
    saveToLocalStorage() {
      const dailyData = { "boardData" : this.boardData, "playerData" : this.playerData};
      localStorage.setItem( this.boardId, JSON.stringify(dailyData));
    },
    toggleHints() {
      this.showHints = !this.showHints;
      sessionStorage.setItem('showHints', this.showHints);
    },
    toggleTilesStarting() {
      this.showTilesStarting = !this.showTilesStarting;
      sessionStorage.setItem('showTilesStarting', this.showTilesStarting);
    },
    toggleTilesUsed() {
      this.showTilesUsed = !this.showTilesUsed;
      sessionStorage.setItem('showTilesUsed', this.showTilesUsed);
    },
    toggleShare() {
      this.showShare = !this.showShare;
    },
    toggleResultList() {
      if (this.isSmallScreen) {
        this.showResultList = !this.showResultList;
        this.showBoard = !this.showBoard;
        if (this.showResultList) {
          this.result = '';
        }
      }
    },
    cleanDica() {
      this.dica = "";
    },
    openDictLink(word) {
      if (word.indexOf('*') === -1) {
        window.open('https://pt.wiktionary.org/wiki/' + word.toLowerCase());
      }
    },
    getDica() {
      let guesses = this.remainingGuesses
      if (this.dica === "" && guesses.length > 0) {
        if (!this.showBoard) {
          this.toggleResultList();
        }
        const num = Math.floor(Math.random() * guesses.length);
        const word = guesses[num];
        this.dica = this.boardData.normalGuesses[word];
      }
    },
    getHintMask(word) {
      let maskedWord = '';
      for (let i = 0; i < word.length; i++) {
        if (i === 0 || 
          (i === 1 && word.length > 6) || 
          (i === word.length - 1 && word.length > 5) ||
          (i === word.length - 2 && word.length > 7)) {
          maskedWord += word[i];
        } else {
          maskedWord += '*';
        }
      }
      return maskedWord;
    },
    getCurrentTime() {
      const date = new Date();
      return date.getTime();
    },

    fixResultDisplay() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      if (document.getElementById("result").style) {
        document.getElementById("result").style.opacity = '1';
      }
    },
    getResult (guess) {
      this.fixResultDisplay()
      if (guess.length < this.wordMinLength) {
          this.result = 'A palavra tem que ter ao menos ' + this.wordMinLength + ' letras';
        } else {
          if (this.playerData.correctGuesses.includes(guess) || this.playerData.correctBonusGuesses.includes(guess)) {
            this.result = 'A palavra ' + guess + ' já foi encontrada';
          } else if (this.remainingGuesses.includes(guess)) {
            this.result = guess + '!';
            this.updateData(guess, true);
            this.checkEndGame();
          } else if (Object.keys(this.boardData.bonusGuesses).includes(guess)) {
            this.result = guess + ' é uma palavra bônus!';
            this.updateData(guess, false);
          } else {
            this.result = guess + ' não é uma palavra válida';
          }
        }
      this.timeOut = setInterval(() => document.getElementById("result").style.opacity = '0', 3000);

    },

    updateData(guess, isNormalGuess) {
      //remove the guess from the possible list and add to the correct list
      if (isNormalGuess) {
        //this.remainingGuesses.splice(this.remainingGuesses.indexOf(guess), 1)
        this.playerData.correctGuesses.push(guess);
        if (this.playerData.correctGuesses.length === 1) {
          this.playerData.startTime = this.getCurrentTime();
        } else {
          this.playerData.endTime = this.getCurrentTime();
        }
        this.playerData.wordsLeft[guess.length] -= 1;
        //update the tile counts
        const combo = this.boardData.normalGuesses[guess];  
        this.playerData.tilesStarting[parseInt(combo.substring(0,2))] -= 1;
        for (let i = 0; i < combo.length; i += 2) {
          this.playerData.tilesUsed[parseInt(combo.substring(i, i+2))] -= 1;
        }
      } else {
        this.playerData.correctBonusGuesses.push(guess);
      }
      this.saveToLocalStorage()
    },
    getSessionStorage() {
      if (sessionStorage.getItem('showHints') !== null) {
        this.showHints = sessionStorage.getItem('showHints');
      }
      if (sessionStorage.getItem('showTilesUsed') !== null) {
        this.showTilesUsed = sessionStorage.getItem('showTilesUsed');
      }
      if (sessionStorage.getItem('showTilesStarting') !== null) {
        this.showTilesStarting = sessionStorage.getItem('showTilesStarting');
      }
    },
    checkEndGame() {
      if(this.playerData.correctGuesses.length === this.boardData.totalWords) {
        this.playerData.endTime = this.getCurrentTime();
        this.showShare = true;
      }
    }
  }
}
</script>

<style>
  .placar {
    width: 100vw;
  }
  .placar-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin: 10px 0 20px 0;
  }
  .result {
    height: 20px;
    transition: 1s;
    font-size: 1.5em;
    margin: 5px;
  }
  .grade {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 100px;
    margin-top: 20px;
  }
  .game {
    height: 100%;
  } 
  .result-list {
    display: flex;
    max-height: calc(100vh - 250px );
    justify-content: left;
    flex-direction: column;
    flex-flow: center;
    overflow: auto;
    scrollbar-color: #222 #444;
  }
  .word-list {
    width: 300px;
    padding: 0px;
    display: flex;
    justify-content: left;
    flex-direction: column;
    flex-flow: center;
    margin-bottom: 20px;
    
  }
  .bonus-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: left;
  }
  .hint-container {
    text-align: left;
    display: flex;
    flex-flow: column;
  }
  .checkbox-title {
    font-size: 0.9em;
  }
  input[type='checkbox'] {
    accent-color:var(--maincolor);
  }
  .score-title {
    padding: 0px;
    font-size: 1.8em;
  }
  .score-title i{
    font-size: 0.9em;
  }
  .words-header {
    text-align: left;
    height: fit-content;
    padding: 0px;
    margin: 5px 0px 5px 0px;
    font-size: 1.15em;
    color: var(--maincolor);
  }
  .words-header i{
    font-size: 0.7em;
  }
  .word-list-header {
    color: white;
    padding: 0px;
    margin: 5px;    
    margin-left: 0px;
    text-align: left;
    font-size: 1.1em;
    font-weight: bold;    
  }
  .word-list-by-length {
    padding: 0;
    margin: 0px;
    font-size: 1.1em;
    display: flex;
    flex-wrap: wrap;
    line-height: 18px;
    gap: 13px;
    justify-content: left;
    text-align: left;
  }
  .words {
    list-style-type: none;
    cursor: pointer;
  }
  
  .fa.fa-book {
    font-size: 26px;
  }
  .fa.fa-share-alt {
    font-size: 26px;
  }
  .fa.fa-gift {
    font-size: 20px;
  }
  
  @media only screen and (max-width: 500px) { 
  .words {
    list-style-type: none;
    font-size: 1.1em ;
  }
  .word-list-by-length {
    line-height: 20px;
    gap: 15px;
  }
  .word-list-header {
    font-size: 1.5em;   
  }
  .words-header{
    font-size: 1.5em;
  }  
  .checkbox-title {
    font-size: 1.2em;
  }
  .word-list-header {
    font-size: 1.3em;  
  }
  .grade {
    margin-top: 0;
    gap: 5px;
  }
  .bonus-container {
    margin-top: 0;
    line-height: 20px;
    gap: 15px;
    font-size:1.1em;
  }
  }
</style>