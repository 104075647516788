<template>
  <div>
    <h1 class="member-header">Estatísticas</h1>
    <br>
    <h3>EM CONSTRUÇÃO</h3>
    <br>
    <i class="fa fa-cogs"></i>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.fa.fa-cogs {
  font-size: 56px;
}
</style>