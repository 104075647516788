<template>
  <transition name="modal">
    <div class="modal-mask" v-if="isVisible">
      <div class="modal-overlay">
        <div class="modal-content">
          <div class="close-container">
            <i class="fa fa-close" @click="closeModal"></i>
          </div> 
          <h2 class="board-info-title">{{ boardFullDate }}</h2>
          <h3>Total de palavras: {{ totalWords }}</h3>
          <h3 v-if="totalCorrectGuesses > 0 || totalBonusGuesses > 0">
           Placar: {{  totalCorrectGuesses }} (+ {{ totalBonusGuesses }} bônus)</h3>
          <div class="mini-board-container">
           <div class="mini-board b">
            <div v-for="(tile, index) in tiles" :key="index" class="mini-tile">
              <div class="mini-tile-letter">{{ tile }}</div>
            </div>
          </div>
          </div>
          <a href="/">
            <button @click="playBoard"><i class="fa fa-gamepad"></i>Jogar</button>
          </a>
          </div>
        
      </div>
    </div>
  </transition>
</template>

<script>
import { getFullDateName } from '../functions';
import api from '../services/api'; 

export default {
props: ['isVisible', 'boardDate'],
emits: ['close'],
data() {
  return {
    tiles: [],
    totalWords: 0,
    totalCorrectGuesses: 0,
    totalBonusGuesses: 0
  }
},
computed: {
  boardFullDate() {
    return getFullDateName(this.boardDate);
  },
  boardId() {
    return 'daily' + this.boardDate;
  }
},
updated() {
  if (this.boardDate.length === 8) {
    if (localStorage.getItem(this.boardId) !== null) {
      const dailyData = JSON.parse(localStorage.getItem(this.boardId));
      this.updateLocal(dailyData);
      
    } else {
      this.fetchBoardDataFromApi(this.boardId);
      console.log('no data in localStorage for this board')
    }   
  }

},
  methods: {
    closeModal() {
      this.$emit('close')
    },
    async fetchBoardDataFromApi(boardId) {
      try {
        const response = await api.getBoardDataFromAPI(boardId);
        const boardJson = response.data;
        const boardData = JSON.parse(boardJson.Data);
        const playerData = {
          startTime: 0,
          endTime: 0,
          correctGuesses: [],
          correctBonusGuesses: [],
          wordsLeft: boardData.wordCount.slice(),
          tilesStarting: boardData.tilesStarting.slice(),
          tilesUsed: boardData.tilesUsed.slice(),
        } 
        console.log("got board data from API");
        const dailyData = { "boardData" : boardData, "playerData" : playerData};
        localStorage.setItem( this.boardId, JSON.stringify(dailyData));
        this.updateLocal(dailyData);
      } catch (err) {
        console.error(err);
      }
      return
    },
    updateLocal(dailyData) {
      this.tiles = dailyData.boardData.tiles;
      this.totalWords = dailyData.boardData.totalWords;
      this.totalCorrectGuesses = dailyData.playerData.correctGuesses.length;
      this.totalBonusGuesses = dailyData.playerData.correctBonusGuesses.length;
    },
    playBoard() {
      sessionStorage.setItem('boardIdToPlay', this.boardId);
      //this.closeModal();
    }

  }
}
</script>

<style scoped>
.board-info-title {
  margin-top: 0;
}
.b {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3%;
  min-height: 200px;
  min-width: 200px;
  max-height: 200px;
  max-width: 200px;
  margin-bottom: 50px;
}
.mini-board-container {
  width: 100;
  display: flex;
  justify-content: center;
}
.fa-gamepad:hover {
  background: transparent;
}
</style>