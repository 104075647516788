import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HelpView from '../views/HelpView.vue'
import MemberView from '../views/MemberView.vue'
import ArchiveView from '../views/ArchiveView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/help',
    name: 'help',
    component: HelpView
  },
  {
    path: '/member',
    name: 'member',
    component: MemberView
  },
  {
    path: '/archive',
    name: 'Archive',
    component: ArchiveView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
