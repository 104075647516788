<template>
  <transition name="modal">
    <div class="modal-mask" v-if="isVisible">
      <div class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
          <div class="close-container">
            <i class="fa fa-close" @click="closeModal"></i>
          </div>      
          <h3> COMPARTILHE SEU RESULTADO </h3>
          <p> {{ resultadoFinal }} </p>
          <div class="modal-buttons">
            <button @click="shareOnTwitter"><i class="fa fa-twitter"></i>Compartilhe no Twitter</button>
            <button @click="shareOnFacebook"><i class="fa fa-facebook"></i>Compartilhe no Facebook</button>
          </div>
          <p>Um novo tabuleiro é lançado todo dia à meia-noite (0:00 do seu horário local)</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['isVisible', 'totalNormalWords', 'totalBonusWords', 'totalGameTime'],
  emits: ['close'],
  methods: {
    closeModal() {
      this.$emit('close')
    },
    shareOnTwitter() {
      const text = encodeURIComponent(this.resultadoFinal);
      const url = encodeURIComponent('https://www.duelango.com'); 
      const twitterUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
      window.open(twitterUrl, '_blank');
    },

    shareOnFacebook() {
      //const appId = '1633378540782068'; 
      const link = encodeURIComponent('https://www.duelango.com');
      //const facebookUrl = `https://www.facebook.com/dialog/feed?appId=${appId}&link=${link}&redirect_uri=${link}`;
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
      console.log(facebookUrl)
      window.open(facebookUrl, '_blank');
    },
    plural(total) {
      if (!Number.isInteger(total)) {
        return "s"
      }
      if (total != 1) {
        return "s"
      }
      return ""
    }
  },
  computed: {
    
    resultadoFinal() {
      return "Encontrei " + this.totalNormalWords + 
      " palavra" + this.plural(this.totalNormalWords) + " e " + this.totalBonusWords + 
      " palavra" + this.plural(this.totalBonusWords) + " bônus em apenas " + this.totalGameTime 
      + " minuto" + this.plural(this.totalGameTime) + "!"
    }
  }

}
</script>

<style>
 .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  z-index: 9998;
}

.modal-content {
  background-color: #222;
  border: solid var(--maincolor);
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
.close-container {
  
  position:absolute;
  top: 0.5% ;
  left: 92.5%; 
}

.modal-buttons {
  margin: 20px 0;
}

button {
  background-color: var(--maincolor);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
  border-radius: 15px;
}

button:hover {
  background-color: var(--chosencolor);
}
.modal-enter-from, .modal-leave-to {
  opacity: 0;
}
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.5s;
}
.modal-enter-to, .modal-leave-from {
  opacity: 1;
}
.fa-facebook, .fa-twitter:hover {
  background: transparent;
}
</style>