<template>
    <div class="member-menu">
      <i id="tab-login" class="fa fa-id-badge" @click="showTab('login')"></i>
      <i id="tab-settings" class="fa fa-gear" @click="showTab('settings')"></i>
      <i id="tab-stats" class="fa fa-bar-chart" @click="showTab('stats')"></i>
    </div>
    <br>
    <MemberSettings v-show="menuChoice==='settings'"></MemberSettings>
    <MemberLogin v-show="menuChoice==='login'"></MemberLogin>
    <MemberStats v-show="menuChoice==='stats'"></MemberStats>

</template>

<script>
import MemberSettings from '../components/MemberSettings.vue'
import MemberLogin from '../components/MemberLogin.vue'
import MemberStats from '../components/MemberStats.vue'

export default {
  name: 'MemberView',
  components: {MemberSettings, MemberLogin, MemberStats},
  data() {
    return {
      menuChoice : "settings"
    }
  },
  mounted() {
    document.getElementById('tab-' + this.menuChoice).style.setProperty('background', 'var(--maincolor)');
  },
  methods: {
    showTab(tab) {
      this.menuChoice = tab
      document.getElementById('tab-login').style.setProperty('background', '#222');
      document.getElementById('tab-settings').style.setProperty('background', '#222');
      document.getElementById('tab-stats').style.setProperty('background', '#222');
      document.getElementById('tab-' + this.menuChoice).style.setProperty('background', 'var(--maincolor)');
    }
  }
}
</script>

<style>
.member-menu {
  display: flex;
  flex-direction: row;
  width: 50px;
  justify-content: center;
  gap: 25px;
}
.fa.fa-gear, .fa.fa-id-badge, .fa.fa-bar-chart {
  font-size: 36px;
}
.member-header {
  margin-top: 0px;
}
</style>