<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

  <nav>
    <div class="duelango-header">
      <div class="left-logos">
        <div>
          <router-link class="router" to="/member"><i class="fa fa-id-card-o"></i><span class="hover-text">Membros</span></router-link>
        </div>
        <router-link class="router" to="/archive"><i class="fa fa-calendar"></i><span class="hover-text">Arquivo</span></router-link> 
      </div>
      <div class="duelango-logo">
        <router-link to="/">dueLango</router-link>
      </div>
      <div class="right-logos">
        <router-link class="router" to="/"><i class="fa fa-gamepad"></i><span class="hover-text">Jogar</span></router-link>
        <router-link class="router" to="/help"><i class="fa fa-question-circle"></i><span class="hover-text">Ajuda</span></router-link>
        
      </div>
    </div>
  </nav>

  <div class="app-body">
    <router-view/>
  </div>

</template>

<script>
import { themes, defaultTheme } from './themes.js';

export default{
  beforeMount() {
    let userTheme
    if (localStorage.getItem('theme')) {
      userTheme = localStorage.getItem('theme')
    } else {
      userTheme = defaultTheme
    }
    const theme = themes[userTheme];
    Object.keys(theme).forEach(key => {
      document.documentElement.style.setProperty(`--${key}`, theme[key]);
    });
  }
}

</script>

<style>
#app {
  font-family:'RadioCanada';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}
@font-face {
   font-family: RadioCanada;
   src: url(../assets/RadioCanadaBig-VariableFont_wght.ttf);
} 
@font-face {
  font-family: PseudoAPL;
  src: url(../assets/PAPL_.ttf);
}
html, body {
  margin: 0; 
  height: 100svh;
  overflow: hidden;
  user-select: none;
}
body {
  color: white; 
  background-color: #222;
  min-width: 360px;
}
.app-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}
a {
  color: white ;
  text-decoration: none;
}
.router {
  position: relative;
  display: flex;
  align-items: center;
}
.hover-text {
  display: none;
}


.duelango-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 2%;
}
.duelango-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  margin: 10px;
  border-radius: 10px; 
  background-color: var(--maincolor);
  color: white;
  line-height: 1;
  font-weight: bold;
  font-size: 3.3em;
  font-family: 'PseudoAPL';
  user-select: none;
}
.duelango-logo:hover {
  background-color:var(--maincolor);
}
.left-logos, .right-logos  {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items:center;
  font-size: 28px;
}
.fa {
  border-radius: 25%;
  padding: 5px;

  line-height: 50%;
  color: white;
  }
.fa:hover {
    background:var(--maincolor);
}
.fa-facebook:hover {
  background: var(--chosencolor);
}
.fa-twitter:hover {
  background: var(--chosencolor);
}
.fa:active {
  background: var(--chosencolor);
}

@media only screen and (min-width: 500px) and (min-height: 600px)  {
  .hover-text {
    display: block;
    color: var(--maincolor);
    border-radius: 25%;
    font-weight: bold;
    padding: 5px;
    font-size:.5em;
}
.left-logos .router .hover-text {
  position: absolute;
  white-space: nowrap;
  right: 100%;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateX(10px);
}
.right-logos .router .hover-text {
  position: absolute;
  white-space: nowrap;
  left: 100%;
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateX(-10px);
}
.right-logos .router:hover .hover-text {
  opacity: 1;
  transform: translateX(0);
}
.left-logos .router:hover .hover-text {
  opacity: 1;
  transform: translateX(0);
}
}

@media only screen and (max-width: 500px) {
  
  body { 
    margin : 0;
  }

  .duelango-logo {
    padding: 15px 25px 15px 25px ;
    font-size: 2.6em;
    margin-left: 0px;
    margin-right:0px;
    user-select: none;
  }
  header {
    padding-top: 5px;
  }
  .duelango-header {
    align-items: normal;
  }


  .result {
    margin-left: 20px;
    margin-right: 20px;
    
  }
  .words {
    list-style-type: none;
    cursor: pointer;
  }

}
@media only screen and (max-height: 500px) {
  body { 
    margin : 0;
  }
  .duelango-logo {
    font-size: 1.8em;
    padding: 10px 25px 10px 25px ;
  }

  .fa {
    font-size: 24px;
  }
  header {
    padding-top: 5px;
  }
  .left-logos, .right-logos  {
    flex-direction: row;
    justify-content: baseline;
    align-content: baseline;
    
  }

}

</style>
